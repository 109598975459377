/**
 * ==============================================================
 * EOS - RESPONSIVE BOOTSTRAP MOBILE APP LANDING PAGE TEMPLATE
 * ==============================================================
 */

/**
 * ========================================
 * GENERAL STYLES
 * ========================================
 */

html {
	height: 100% !important;
}

body{

}


/**
 * ========================================
 * MAIN LAYOUT
 * ========================================
 */

/* DOCUMENT */
.document {
	height: 100%;
	position: relative;
}

/* PRELOADER */
.preloader {
	display: table;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	color: #444444;
	background-image: none;
	z-index: 9999;
}
.preloader-inner {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.preloader-logo {
	display: inline-block;
	margin-top: -20px;
}
.preloader-logo img {
	display: none;
	max-height: 90px;
}
.preloader-logo strong {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: 45px;
	margin-left: -45px;
	display: none;
	width: 90px;
	font-size: 11px;
	font-weight: 800;
	letter-spacing: 2px;
	text-align: center;
	text-transform: uppercase;
	-webkit-animation: preloader-loading 2s infinite;
	animation: preloader-loading 2s infinite;
}
@-webkit-keyframes preloader-loading {
	0% { opacity: 0; }
	50% { opacity: 1; }
	100% { opacity: 0; }
}
@keyframes preloader-loading {
	0% { opacity: 0; }
	50% { opacity: 1; }
	100% { opacity: 0; }
}
.with-preloader .document {
	opacity: 0;
	-webkit-transition: all ease 0.5s;
	transition: all ease 0.5s;
}
.with-preloader.preloader-done .document {
	opacity: 1;
}
.with-preloader.preloader-done .preloader {
	display: none;
}
.with-preloader .wow {
	animation-play-state: paused;
	-webkit-animation-play-state: paused;
}
.with-preloader.preloader-done .wow {
	animation-play-state: running;
	-webkit-animation-play-state: running;
}

/* SECTION */


.black-background-overlay,
.black-dot-background-overlay,
.accent-background-overlay {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}

.accent-background-overlay {
	opacity: 0.95;
}
.black-dot-background-overlay {
	background-color: rgba(0,0,0,0.5);
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUM4Q0NDRTgyNDQ2MTFFNEE2NEVDNzc4QzFDRjlCRUQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUM4Q0NDRTkyNDQ2MTFFNEE2NEVDNzc4QzFDRjlCRUQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxQzhDQ0NFNjI0NDYxMUU0QTY0RUM3NzhDMUNGOUJFRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxQzhDQ0NFNzI0NDYxMUU0QTY0RUM3NzhDMUNGOUJFRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkB60N8AAAAYSURBVHjaYmJgYPgPBAxMQIIRyGEACDAARwYG/12eAo8AAAAASUVORK5CYII%3D);
	background-repeat: repeat;
	background-position: center center;
}
.parallax-background {
	background-attachment: fixed;
	background-position: top center;
}
.video-background-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}
.container {
	position: relative;
}
.mobile-device .parallax-background {
	background-attachment: scroll;
	background-position: center center !important;
}
.mobile-device .parallax {
	top: 0 !important;
}

/* OTHER GENERAL CLASSES */
.clear {
	clear: both;
}
.blur {
	-webkit-filter: blur( 10px );
	filter: blur( 10px );
}

/** CANVAS **/



/**
 * ========================================
 * BUTTONS
 * ========================================
 */

.btn {
	padding: 14px 22px;
	font-weight: 800;
	letter-spacing: 1px;
	text-transform: uppercase;
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
}
.btn:focus, .btn:active:focus, .btn.active:focus {
	outline: 0;
}
.btn-lg {
	padding: 20px 25px;
}
.btn-lg, .btn-app-download {
	font-size: 14px;
	letter-spacing: 1.5px;
}
.btn-app-download {
	position: relative;
	padding: 10px 20px 10px 60px;
	text-align: left;
}
.btn-app-download strong {
	display: block;
	margin-bottom: 6px;
	font-weight: 800;
}
.btn-app-download span {
	display: block;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: 0.5px;
	margin-top: -3px;
	opacity: 0.8;
}
.btn-app-download i {
	position: absolute;
	top: 0;
	left: 0;
	margin: 10px 0 0 15px;
	font-size: 40px;
	line-height: 1;
}
.btn-black {
	background-color: #222222;
	color: #ffffff;
}
.btn-black:focus, .btn-black:hover {
	background-color: #000000;
	color: #ffffff;
}
.btn-gray {
	background-color: #999999;
	color: #ffffff;
}
.btn-gray:focus, .btn-gray:hover {
	background-color: #777777;
	color: #ffffff;
}
.btn-ios {
	background-color: #999999;
	color: #ffffff;
}
.btn-ios:focus, .btn-ios:hover {
	background-color: #777777;
	color: #ffffff;
}
.btn-android {
	background-color: #a4c639;
	color: #ffffff;
}
.btn-android:focus, .btn-android:hover {
	background-color: #8bad20;
	color: #ffffff;
}
.btn-windows-phone {
	background-color: #e81123;
	color: #ffffff;
}
.btn-windows-phone:focus, .btn-windows-phone:hover {
	background-color: #cf000a;
	color: #ffffff;
}

/**
 * ========================================
 * FORMS
 * ========================================
 */

.form-control::-webkit-input-placeholder {
	color: #aaaaaa;
}
.form-control:-moz-placeholder {
	color: #aaaaaa;  
}
.form-control::-moz-placeholder {
	color: #aaaaaa;  
}
.form-control:-ms-input-placeholder {
	color: #aaaaaa;  
}
.form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #cccccc;
}
.form-control {
	font-size: inherit;
	line-height: inherit;
	height: 50px;
	padding: 11px 22px;
	color: inherit;
	border-color: #dddddd;
	background-color: #ffffff;
}
.form-control-lg {
	font-size: 18px;
	line-height: 30px;
	height: 60px;
	padding: 15px 25px;
}
.section-inverse-color .form-control {
	color: #777777;
}
.form-validation {
	display: none;
	padding: 15px 25px;
}
.form-validation.alert-danger {
	color: #ffffff;
	background-color: #a94442;
	background-color: rgba(169,68,66,0.8);
	border-color: #a94442;
}
.form-validation.alert-success {
	color: #ffffff;
	background-color: #3c763d;
	background-color: rgba(60,118,61,0.8);
	border-color: #3c763d;
}

/**
 * ========================================
 * HEADINGS
 * ========================================
 */

h1, h2, h3, h4, h5, h6 {
	color: #444444;
}

.section-inverse-color h1,
.section-inverse-color h2,
.section-inverse-color h3,
.section-inverse-color h4,
.section-inverse-color h5,
.section-inverse-color h6 {
	color: #ffffff;
}
.section-heading {
	position: relative;
	padding-bottom: 20px;
	margin: 0 0 30px;
	font-weight: 300;
	font-size: 32px;
	line-height: 42px;
}
.section-heading:after {
	content: "";
	position: absolute;
	top: 100%;
	left: 0;
	height: 2px;
	width: 120px;
}
.section-heading.text-center:after {
	left: 50%;
	margin-left: -60px;
}
@media ( min-width: 768px ) {
	.section-heading {
		font-size: 36px;
		line-height: 46px;
	}
}
@media ( min-width: 992px ) {
	.section-heading {
		font-size: 40px;
		line-height: 50px;
	}
}

/**
 * ========================================
 * LIST
 * ========================================
 */

.list-with-icons {
	list-style: none;
	padding: 0;
}
.list-with-icons li {
	position: relative;
	padding-left: 50px;
	margin: 10px 0;
}
.list-with-icons li > i[class*="icon-"],
.list-with-icons li > i[class*="fa-"] {
	position: absolute;
	top: 5px;
	left: 10px;
	width: 20px;
	font-size: 20px;
	line-height: 1;
	text-align: center;
}

/**
 * ========================================
 * PHONE IMAGE
 * ========================================
 */

.phone-image-double {
	position: relative;
}
.phone-image-front {
	z-index: 1;
}
.phone-image-double {
	max-width: 50%;
}
.phone-image-right {
	margin-left: -25%;
}
@media ( min-width: 768px ) and ( max-width: 991px ) {
	.phone-image-right {
		margin-left: -20%;
	}
}

/**
 * ========================================
 * HEADER SECTION
 * ========================================
 */

.header-section {
	margin-bottom: 0;
	margin-top: -120px;
	-webkit-transition: all ease 1.5s;
	transition: all ease 1.5s;
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.header-section.header-floating.floating {
	margin-top: 0;
}
.header-section.navbar-default {
	background-color: #ffffff;
	background-color: rgba(255,255,255,0.95);
	border-bottom-color: #eeeeee;
}
.navbar-toggle {
	margin-top: 13px;
	margin-bottom: 13px;
}
.navbar-logo {
	max-height: 50px;
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 46px;
	white-space: nowrap;
}
.navbar-logo:before {
	content: "";
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
}
.navbar-nav > li > a {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 25px;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}
.navbar-default .navbar-nav > li > a {
	color: #444444;
}
@media ( min-width: 768px ) {
	.navbar-nav > li > a {
		margin-top: 17.5px;
		margin-bottom: 17.5px;
	}
}

/**
 * ========================================
 * HERO SECTION
 * ========================================
 */

.hero-section {
	height: 100%;
	/*
	background-image: url(../images/backgrounds/winterlandschaft_klein_02.jpg);
	background-repeat: no-repeat;
	background-size: cover;*/
}
.hero-logo {
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
}
.hero-logo img {
	max-height: 70px;
}

.hero-section .container {
	height: 100%;
}
.hero-content {
	display: table;
	height: 100%;
	width: 100%;
	text-align: center;
}
.hero-section .download-buttons {
	margin: 0 -10px;
}
.hero-section .download-buttons .btn-app-download {
	margin: 0 10px 10px;
}
.hero-text {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	padding-top: 140px;
	padding-bottom: 45px;
}
.hero-watch-video-link {
	display: inline-block;
	margin-top: 10px;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #444444;
	color: rgba(68,68,68,0.7);
}
.hero-watch-video-link:focus,
.hero-watch-video-link:hover {
	color: #444444;
}
.section-inverse-color .hero-watch-video-link {
	display: inline-block;
	margin-top: 10px;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #ffffff;
	color: rgba(255,255,255,0.7);
}
.section-inverse-color .hero-watch-video-link:focus,
.section-inverse-color .hero-watch-video-link:hover {
	color: #ffffff;
}
.hero-watch-video-link i {
	margin-top: -4px;
	margin-right: 10px;
	font-size: 20px;
	vertical-align: middle;
}
@media ( max-width: 991px ) {
	.hero-section {
		height: auto;
		padding: 100px 0;
	}
}
@media ( max-width: 767px ) {
	.hero-section {
		padding: 80px 0;
	}
	.hero-content {
		text-align: center;
	}
}
@media ( min-width: 768px ) {
	.hero-title {
		font-size: 40px;
		line-height: 50px;
	}
	.hero-text {
		max-width: 640px;
	}
}
@media ( min-width: 992px ) {
	.hero-logo {
		margin-top: 40px;
	}
	.hero-title {
		font-size: 50px;
		line-height: 60px;
	}
	.hero-text {
		max-width: 720px;
	}
}
@media ( min-width: 1200px ) {
	.hero-text {
		max-width: 940px;
	}
}

/**
 * ========================================
 * FEATURES SECTION
 * ========================================
 */

.features-section {
	padding: 80px 0 0;
}
.features-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
.features-list-item {
	margin: 30px 0;
}
@media ( max-width: 991px ) {
	.features-list {
		text-align: center;
	}
}
@media ( min-width: 768px ) {
	.features-section {
		padding: 100px 0 0;
	}
	.features-list-item {
		margin: 50px 0;
	}
}
@media ( min-width: 992px ) {
	.featuers-list-wrapper {
		margin-top: 20px;
	}
	.features-section {
		padding: 120px 0 0;
	}
	.features-list.features-list-left {
		text-align: right;
	}
	.features-list-item {
		margin: 70px 0;
	}
}
@media ( min-width: 1200px ) {
	.features-list-item {
		margin: 90px 0;
	}
}

/**
 * ========================================
 * FEATURES WITH ICONS SECTION
 * ========================================
 */

.features-icons-section {
	padding: 80px 0;
}
.features-icon-list-item {
	position: relative;
	padding-left: 95px;
	margin-top: 30px;
	margin-bottom: 30px;
	min-height: 60px;
}
.features-icon-list-item i {
	position: absolute;
	top: 0;
	left: 0;
	width: 60px;
	height: 60px;
	padding: 14px;
	margin-left: 15px;
	font-size: 32px;
	line-height: 1;
	text-align: center;
	background-color: #cccccc;
	background-color: rgba(0,0,0,0.15);
	color: #ffffff;
	border-radius: 6px;
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
}
.section-inverse-color .features-icon-list-item i {
	background-color: #555555;
	background-color: rgba(255,255,255,0.25);
	color: #222222;
}
.features-icon-list-item h5 {
	margin: 0 0 3px;
}
.features-icon-list-item p {
	margin-bottom: 0;
}
@media ( min-width: 768px ) {
	.features-icon-list-item {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.features-icons-section {
		padding: 100px 0;
	}
}
@media ( min-width: 992px ) {
	.features-icons-section {
		padding: 120px 0;
	}
}

/**
 * ========================================
 * VIDEO SECTION
 * ========================================
 */

.video-section {
	padding: 80px 0;
	background-image: url(../images/backgrounds/langing_video_01.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}
.video-embed {
	position: relative;
	padding-top: 56.25%; /* 16:9 */
	height: 0;
	background-color: #000000;
	-webkit-box-shadow: 0 5px 15px rgba(0,0,0,0.2);
	box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}
.video-embed iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
@media ( min-width: 768px ) {
	.video-section {
		padding: 100px 0;
	}
}
@media ( min-width: 992px ) {
	.video-section {
		padding: 120px 0;
	}
}

/**
 * ========================================
 * DESCRIPTION SECTION
 * ========================================
 */

.description-section {
	padding: 80px 0;
}
@media ( min-width: 768px ) {
	.description-section {
		padding: 100px 0;
	}
}
@media ( min-width: 992px ) {
	.description-section {
		padding: 120px 0;
	}
	.description-section .phone-image-double {
		max-width: 60%;
	}
	.description-section .phone-image-right {
		margin-left: -30%;
	}
}

/**
 * ========================================
 * TESTIMONIALS SECTION
 * ========================================
 */

.testimonials-section {
	padding: 80px 0;
	background-image: url(../images/backgrounds/testimonials-bg.jpg);
	background-repeat: repeat;
	color: #ffffff;
}
.testimonials-carousel {
	text-align: center;
	margin-bottom: -40px;
}
.testimonial-items {
	list-style: none;
	padding: 0;
	margin: 0;
}
.testimonial-items li {
	max-width: 90%;
	margin: 0 auto;
}
.testimonial-text {
	font-size: 24px;
	font-style: italic;
	font-weight: 300;
	line-height: 36px;
	margin-bottom: 40px;
	letter-spacing: 1px;
}
.testimonial-name {
	opacity: 0.7;
	margin-bottom: 20px;
}
.testimonial-name:before, .testimonial-name:after {
	content: "---";
	margin: 0 10px;
	letter-spacing: -2px;
}
@media ( min-width: 768px ) {
	.testimonials-section {
		padding: 100px 0;
	}
	.testimonial-items li {
		max-width: 560px;
	}
}
@media ( min-width: 992px ) {
	.testimonials-section {
		padding: 120px 0;
	}
	.testimonial-items li {
		max-width: 640px;
	}
}
@media ( min-width: 1200px ) {
	.testimonial-items li {
		max-width: 720px;
	}
}

/**
 * ========================================
 * PRICING SECTION
 * ========================================
 */

.pricing-section {
	padding: 80px 0;
}
.pricing-table {
	list-style: none;
	padding: 0;
	margin-top: 30px;
	margin-bottom: 0;
}
.pricing-package {
	position: relative;
	margin: 20px 0;
	padding: 29px 29px 29px;
	border-radius: 4px;
	background-color: #ffffff;
	border-width: 1px;
	border-style: solid;
	border-color: #dddddd;
	border-color: rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.025);
	box-shadow: 0 2px 4px rgba(0,0,0,0.025);
}
.pricing-package-strip {
	height: 10px;
	margin: -30px -30px 30px -30px;
	border-radius: 4px 4px 0 0;
}
.pricing-package-header {
	position: relative;
	min-height: 50px;
	padding-right: 90px;
	margin-bottom: 30px;
}
.pricing-package-header h4 {
	margin: 0 0 10px;
	font-weight: 800;
	font-size: 16px;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.pricing-package-header h4 .label {
	display: inline-block;
	margin-top: -4px;
	font-size: 10px;
	line-height: 12px;
	letter-spacing: 1px;
	vertical-align: middle;
}
.pricing-package-header p {
	margin-bottom: 0;
	color: #aaaaaa;
	font-size: 13px;
	line-height: 21px;
}
.pricing-package-header .price {
	position: absolute;
	top: 0;
	right: 0;
	max-width: 80px;
	margin-top: -10px;
	text-align: right;
	color: #444444;
}
.pricing-package-header .price-currency {
	font-size: 18px;
	line-height: 1;
	font-weight: 700;
}
.pricing-package-header .price-number {
	font-size: 40px;
	line-height: 1;
	white-space: nowrap;
	letter-spacing: -0.5px;
}
.pricing-package-header .price-period {
	display: block;
	white-space: nowrap;
	font-size: 10px;
	line-height: 1;
	text-transform: uppercase;
	color: #777777;
}
.pricing-package-items {
	list-style: none;
	padding: 0;
	margin: 0;
}
.pricing-package-items li {
	position: relative;
	padding-left: 40px;
	margin: 2px 0;
}
.pricing-package-items li > i {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 18px;
	font-size: 18px;
	line-height: 1;
	text-align: center;
}
.pricing-package-items li > i.fa-times {
	color: #dddddd;
}
@media ( min-width: 768px ) {
	.pricing-section {
		padding: 100px 0;
	}
}
@media ( min-width: 992px ) {
	.pricing-section {
		padding: 120px 0;
	}
	.pricing-package-featured {
		margin: 0;
	}
	.pricing-package-featured .pricing-package-strip {
		margin-bottom: 40px;
	}
	.pricing-package-featured .pricing-package-header {
		margin-bottom: 40px;
	}
	.pricing-package-featured .pricing-package-items {
		margin-bottom: 20px;
	}
}

/**
 * ========================================
 * SCREENSHOTS SECTION
 * ========================================
 */

.screenshots-section {
	padding: 80px 0;
}
.screenshot-images {
	list-style: none;
	padding: 0;
	margin: 0;
}
.screenshot-image {
	display: block;
	margin: 15px;
	padding: 5px;
	border-radius: 8px;
	background-color: #dddddd;
	background-color: rgba(0,0,0,0.1);
}
.screenshot-image:focus,
.screenshot-image:hover {
	margin-top: 0;
}
.screenshot-image-inner {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
}
.screenshot-image-inner img {
	display: block;
	border-radius: 4px;
}
.screenshot-image-inner .hover {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	opacity: 0;
	border-radius: 4px;
	background-color: rgba(0,0,0,0.7);
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
}
.screenshot-image-inner .hover:before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}
.screenshot-image-inner .hover i {
	display: inline-block;
	vertical-align: middle;
	width: 50px;
	color: #ffffff;
	font-size: 50px;
	line-height: 1;
}
.screenshot-image:focus .screenshot-image-inner .hover,
.screenshot-image:hover .screenshot-image-inner .hover {
	opacity: 1;
}
@media ( min-width: 768px ) {
	.screenshots-section {
		padding: 100px 0;
	}
}
@media ( min-width: 992px ) {
	.screenshots-section {
		padding: 120px 0;
	}
}

/**
 * ========================================
 * PRESS SECTION
 * ========================================
 */

.press-section {
	padding: 40px 0;
	background-image: none;
}
.press-logos {
	text-align: center;
}
.press-logos span {
	display: inline-block;
	margin: 0 20px;
}
@media ( min-width: 768px ) {
	.press-section {
		padding: 50px 0;
	}
}
@media ( min-width: 992px ) {
	.press-section {
		padding: 60px 0;
	}
}

/**
 * ========================================
 * SUBSCRIBE SECTION
 * ========================================
 */

.subscribe-section {
	/*padding: 80px 0;
	background-image: url(../images/backgrounds/landing_kontakt_01.jpg);
	background-repeat: no-repeat;
	background-size: cover;*/
}
.subscribe-section .section-heading:after {
	background-color: #ffffff;
	background-color: rgba(255,255,255,0.5);
}
.subscribe-form-email, .subscribe-form-submit {
	margin-bottom: 10px;
}
.subscribe-form-submit {
	width: 100%;
}
@media ( min-width: 768px ) {
	.subscribe-section {
		padding: 100px 0;
	}
	.subscribe-section {
		padding: 120px 0;
	}
	.subscribe-form-input {
		position: relative;
		padding-right: 150px;
	}
	.subscribe-form-email {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.subscribe-form-submit {
		position: absolute;
		top: 0;
		right: 0;
		width: 150px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

/**
 * ========================================
 * CONTACT SECTION
 * ========================================
 */

.contact-section {
	padding: 80px 0;
	background-image: none;
	background-color: #f0f0f0;
}
.contact-form textarea {
	resize: vertical;
}
@media ( min-width: 768px ) {
	.contact-section {
		padding: 100px 0;
	}
}
@media ( min-width: 992px ) {
	.contact-section {
		padding: 120px 0;
	}
}

/**
 * ========================================
 * FOOTER SECTION
 * ========================================
 */

.footer-section {
	border-top: 10px solid #f0f0f0;
	padding: 120px 0 50px;
	color: #cccccc;
	text-align: center;
}

.contact-toggle {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -45px;
	border-radius: 0 0 90px 90px;
	height: 45px;
	width: 90px;
	padding: 4px 0 11px;
	font-size: 24px;
	line-height: 30px;
	background-color: #f0f0f0;
	color: #777777;
	text-align: center;
}
.contact-toggle:focus, .contact-toggle:hover {
	color: #444444;
}
.footer-section .download-buttons {
	margin: 0 -10px;
	margin-bottom: 70px;
}
.footer-section .download-buttons .btn-app-download {
	margin: 0 10px 10px;
}
.social-media-links {
	margin: 0;
	margin-bottom: 50px;
	padding: 0;
	list-style: none;
}
.social-media-links li {
	display: inline-block;
	margin-left: 10px;
}
.social-media-links li:first-child {
	margin-left: 0;
}
.social-media-links li i {
	display: inline-block;
	width: 40px;
	height: 40px;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	color: #ffffff;
}
.social-media-links li a {
	display: inline-block;
	border-radius: 50%;
	background-color: #cccccc;
	background-color: rgba(0,0,0,0.15);
	font-weight: normal;
	color: #777777;
}
.footer-text {
	margin: 0;
	padding: 0;
	list-style: none;
}
.footer-text li {
	display: inline-block;
	margin-left: 10px;
}
.footer-text li:first-child {
	margin-left: 0;
}
.copyright {
	border-top: 1px solid #eeeeee;
	padding-top: 25px;
	text-align: center;
}
.copyright a {
	font-weight: normal;
	color: #cccccc;
}
.copyright a:focus, .copyright a:hover {
	color: #777777;
}
@media ( min-width: 992px ) {
	.footer-section {
		text-align: center;
	}
}

/**
 * ========================================
 * OWL CAROUSEL CUSTOM
 * ========================================
 */

.owl-theme .owl-controls .owl-page span {
	width: 14px;
	height: 14px;
	margin: 4px;
	background-color: #cccccc;
	background-color: rgba(0,0,0,0.15);
	-webkit-transform: scale( 0.75 );
	-moz-transform: scale( 0.75 );
	transform: scale( 0.75 );
	-webkit-transition: all ease 0.25s;
	transition: all ease 0.25s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.section-inverse-color .owl-theme .owl-controls .owl-page span {
	background-color: #aaaaaa;
	background-color: rgba(255,255,255,0.5);
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
	-webkit-transform: scale( 1 );
	-moz-transform: scale( 1 );
	transform: scale( 1 );
}

/**
 * ========================================
 * NIVO LIGHTBOX CUSTOM
 * ========================================
 */

.nivo-lightbox-theme-default.nivo-lightbox-overlay {
	background-color: #000000;
	background-color: rgba(0,0,0,0.8);
}
.nivo-lightbox-theme-default .nivo-lightbox-close {
	width: 26px;
	height: 26px;
}



@media screen and ( max-width: 990px){
	a.button-yearbook{
		margin:15px auto 15px auto;
	}
}

#audio-container{
	display:block;
	
}

@media screen and ( max-width: 850px){
	#audio-container{
		position:absolute;
		left:50%;
		bottom:10px;
		
		tranform:translateX(-50%);
		-webkit-transform:translateX(-50%);
	}
}

#book{
	display:block;
}

#fb5-footer{
	display:block!important;
}

#fb5-logo, #fb5-button-back, #audio-container{
	display:none!important;
}



